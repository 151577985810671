.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    background-color: black;
}

.video-responsive video,
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.video-responsive .matchBar {
    background-color: $warning;
    position: absolute;
    bottom: 58px;
    left: 12px!important;
    width: calc(100% - 24px)!important;
}
.video-responsive .video-invert::after {
    content: ""; position: absolute; left: 46px; bottom: 6px; height: 40px; width: calc(100% - 46px); background-color: black;
}
.video-responsive .video-invert video {
    filter:invert(1);
}