// 
// carousel.scss
//

// carousel indicators style
.carousel-indicators {
    margin-bottom: -2px;
    [data-bs-target] {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 8px;
        height: 8px;
        padding: 0;
        border-radius: 100%;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: $gray-500!important;
        background-clip: padding-box;
        border: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: 0.5;
        &.active {
            opacity: 1;
            background-color: $primary!important;
        }
    }
}

//////////////////////////////////////
// SONGMATE UPCOMING RELEASES CAROUSEL
//////////////////////////////////////
.upcoming-carousel {
    .carousel-control-prev,
    .carousel-control-next {
        opacity: 1;
        top: calc(50% - 48px);
        bottom: auto;
        width: auto;
        width: 32px;
        height: 64px;
        background-color: rgba(230,230,230,0.5);
        transition: all .3s ease;
        &:hover { background-color: $primary; cursor: pointer;
         path { fill: white; stroke: white;}
        }
    }
    .carousel-control-next { right:1px; border-top-left-radius: 8px; border-bottom-left-radius: 8px; }
    .carousel-control-prev { left:1px; border-top-right-radius: 8px; border-bottom-right-radius: 8px; }
}

//////////////////////////////////////
// FANBEE WELCOME CAROUSEL
//////////////////////////////////////
.fanbee-welcome-carousel {
    .carousel-indicators {
        width: auto;
        margin: 0 !important;
        gap: .5rem;
        bottom: auto;
        top: -1rem;
        z-index: 11;
        [data-bs-target] {
            padding: 0;
            border: none;
            margin: 0;
            width: .75rem;
            height: .75rem;
        }

        @include media-breakpoint-up(xxl) {
            top: 0;
            bottom: auto;
            gap: 1rem;
            background: white;
            padding: 0.5rem 1rem 1rem 1rem;
            border-bottom-right-radius: 1.75rem;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
            right: 2rem;
            left: auto;
            border-bottom-left-radius: 1.75rem;
            [data-bs-target] {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    
    }

    .carousel-control-prev, .carousel-control-next { top:50%; bottom: 50%; width: auto; }
    .carousel-control-prev {  width: auto;transform: translateX(-50%); }
    .carousel-control-next { transform: translateX(50%); }


    .carousel-item {
        .image-content {background: rgb(147,115,222);
            background: linear-gradient(0deg, rgba(147,115,222,1) 0%, rgba(203,189,239,1) 100%);}
    }
    
    .wrap-phone-mockup {
        position: absolute; left: 50%; transform: translate(-50%, 0); bottom: 0; width: 85%; height: 85%; max-width: 380px;
        background: url(../../../images/various/iphone14.png) center top no-repeat; background-size: cover;
        filter: drop-shadow(16px 16px 48px rgba(0,0,0,0.75));
        .phone-mockup-content {
            position: absolute;
            top: 34px;
            left: 14px;
            width: calc(100% - 28px);
            height: 100%;
            overflow: hidden;        
            border-top-left-radius: 34px;
            border-top-right-radius: 34px;
    
        }
    }
}
.animated-dash-bg {
    width: 100%; height: 100%; transform: rotate(-45deg) scale(1.25); position: absolute; top: 0; right: 0;
    .dash {
    stroke-dasharray: 700; animation: dash-animation 25s linear infinite; stroke: #FBC02E;
    &_0 {
        animation-delay: 0s;
    }

    &_1 {
        transform: translateY(100px);
        animation-delay: 2.5s;
    }

    &_2 {
        transform: translateY(200px);
        animation-delay: 6s;
    }

    &_3 {
        transform: translateY(300px);
        animation-delay: 4s;
    }

    &_4 {
        transform: translateY(400px);
        animation-delay: 1s;
    }

    &_5 {
        transform: translateY(500px);
        animation-delay: 5s;
    }

    &_6 {
        transform: translateY(600px);
        animation-delay: 3s;
    }

    &_7 {
        transform: translateY(700px);
        animation-delay: 1.5s;
    }

    &_8 {
        transform: translateY(800px);
        animation-delay: 5.5s;
    }

    &_9 {
        transform: translateY(900px);
        animation-delay: 1s;
    }
    }
    @keyframes dash-animation {
        0%      { stroke-dashoffset: 10; stroke: #FBC02E; }
        50%     { stroke: #F7D54D; }
        100%    { stroke-dashoffset: 9800; stroke: #FBC02E; }
    }
}




    

