//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

// Fix password floating input
.input-group input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

/* Scrollbar hiding */
.no-scrollbar {
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;     /* Hide scrollbar for Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;             /* Hide scrollbar for Chrome, Safari, and Opera */
}