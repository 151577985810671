.tiptap {
  padding: 1rem;

  &:focus-visible {
    outline: none;
  }
  a {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
    line-height: 1.5em;
  }
}
