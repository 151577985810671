/**********************************************/
/* TYPOGRAPHY */
/**********************************************/

$paragraph-margin-bottom: 1rem;
$font-family-sans-serif:  'SuisseIntl', sans-serif;
$font-family-monospace:   'SuisseIntlMono', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

$font-family-base:      $font-family-sans-serif;
$font-family-code:      var(--#{$variable-prefix}font-monospace);

$font-size-root:        null;
$font-size-base:        .9rem; // Assumes the browser default, typically `16px`
$font-size-sm:          $font-size-base * .875;
// $font-size-lg:          $font-size-base * 1.25;
$font-size-lg:          1rem;

$font-weight-lighter:   lighter;
$font-weight-light:     300;
$font-weight-normal:    400;
$font-weight-medium:    500;
$font-weight-semibold:  600;
$font-weight-bold:      700;
$font-weight-bolder:    bolder;
$font-weight-base:      $font-weight-normal;

$line-height-base:      1.5;
$line-height-sm:        1.25;
$line-height-lg:        2;


$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
);

$headings-margin-bottom:  $spacer * .5;
$headings-font-family:    null;
$headings-font-style:     null;
$headings-font-weight:    600;
$headings-line-height:    1.2;
$headings-color: null;
// $headings-color: $body-color;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
);

$display-font-family: null;
$display-font-style:  null;
$display-font-weight: 300;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size:    $font-size-base * 1.25;
$lead-font-weight:  300;

// $small-font-size: .875em;
$small-font-size: 0.75rem;

$sub-sup-font-size: 0.75em;

$text-muted: $gray-400;

$initialism-font-size: $small-font-size;

$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: $gray-600;
$blockquote-footer-font-size: $small-font-size;

// $hr-margin-y: $spacer;
$hr-margin-y: $spacer - 0.5rem;
// $hr-color: inherit;
$hr-color: $gray-300;

$hr-border-color: null; // Allows for inherited colors
$hr-border-width: $border-width;
// $hr-opacity: .25;
$hr-opacity: 1;

$legend-margin-bottom: 0.5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;

// $mark-padding: .1875em;
$mark-padding: 0.2em;
$mark-bg: #fcf8e3;

$dt-font-weight: $font-weight-bold;
$nested-kbd-font-weight: $font-weight-bold;
$list-inline-padding: 6px;

// scss-docs-end type-variables



// Characters which are escaped by the escape-svg function
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29'));

///////////////////////////////////////////////////
/* TYPOGRAPHY */
///////////////////////////////////////////////////
$text-title-color: $gray-900;
$page-title-color: inherit;

/* add general.scss (all dashboards) overrides here with !important */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  font-weight: $font-weight-semibold!important;
}
