/**********************************************/
/* SPACING */
/**********************************************/
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1.5rem;
$spacers: (
  0: 0,
  1: calc($spacer / 4),
  2: calc($spacer / 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);
// scss-docs-end spacer-variables-maps