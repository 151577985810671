
///////////////////////////////////////////////////
/* BUTTONS */
///////////////////////////////////////////////////
/* sizes */
.btn {
  height: 35.58px;
  display: flex!important; flex-direction: row; justify-content: center; gap:0.5em; align-items: center; line-height: 1!important;
  white-space: nowrap;

  i       { height: 1em; width: auto; font-size: 1em; line-height: 1; }
  svg     { height: 1em; width: auto; font-size: 1em; fill: currentColor; }
}
.btn-xs {
  height: 24px; font-size: 11px!important; padding: 0 6px!important;
  i       { height: 1em; width: auto; font-size: 1em; }
  svg     { height: 1em; width: auto; font-size: 1em; }
}
.btn-sm {
  height: 28.89px;
  i       { height: 1em; width: auto; font-size: 1em; }
  svg     { height: 1em; width: auto; font-size: 1em; }
}
.btn-lg {
  height: 42px;
  i       { height: 1em; width: auto; font-size: 1em; }
  svg     { height: 1em; width: auto; font-size: 1em; }
}
.btn-xl {
  height: 48px; font-size: 1.1rem; padding: 0 24px;
  i       { height: 1em; width: auto; font-size: 1em; }
  svg     { height: 1em; width: auto; font-size: 1em; }
}
.btn-xxl {
  height: 56px; font-size: 1.2rem; padding: 0 28px;
  i       { height: 1em; width: auto; font-size: 1em; }
  svg     { height: 1em; width: auto; font-size: 1em; }
}

/* btn-light */
.btn-light {
  background: $white!important;
  border-color: $gray-400!important;
  color: $gray-500!important;
  &:hover, &:active , &:focus {
    border-color: $gray-700!important;
    color: $gray-700!important;
  }
}


/* btn in table */
table .btn {
  box-shadow: $box-shadow-sm;
}
.actions-column {
  .btn {
    padding:0 .5em!important;
    i { font-size:1.25em!important; height: auto; }
    svg { height: 100%; width: 1.25em; stroke: inherit; }
  }
}



/* btn in ee landing */
.ee-landing {
  .btn {
    height: auto; display: inline!important;
  }
}


/* btn with pulsating animation */
/* To use follow this:  <button className="btn"><div className="pulse" /><span>Getting Started</span><svg or icon /></button> */

.btn:has(.pulse) { 
  position: relative;
  * { position: relative; z-index: 2;}
  &:hover {
      .pulse {
      animation: pulse-animation 1s linear infinite;}
  }
}

.pulse {
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
  position: absolute!important;
  inset: 0;
  animation: pulse-animation 2s linear infinite;
  z-index: 1!important;

}

@keyframes pulse-animation {
  0% {
      box-shadow: 0 0 0 0 $primary; opacity: .7;
  }
  40% {
      box-shadow: 0 0 0 16px $primary; opacity: 0;
  }
  80% {
      box-shadow: 0 0 0 16px $primary; opacity: 0;
  }
  100% {
      box-shadow: 0 0 0 0 $primary; opacity: 0;
  }
}
  