//
// dropzone.scss
//

.dropzone {
    border: 2px dashed $gray-300;
    transition: all .2s ease-in-out;
    border-radius: $border-radius;
    cursor: pointer;
    min-height: 150px;
    .dz-message {
        text-align: center;
        margin: 2.5rem 0;
        outline: none;
    }
    &.dz-started {
        .dz-message {
            display: none;
        }
    }
    &:hover {
        background: $gray-50;border-color:$gray-400; mix-blend-mode: multiply;
    }
}

.dropzone--disabled {
  @extend .dropzone;
  background-color: $gray-50; cursor: not-allowed;
  &:hover {background: $gray-50;border: 2px dashed $gray-300;}
  .dz-message { opacity: .5;}
}
