/**********************************************/
/* BREAKPOINTS */
/**********************************************/

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1900px,
);
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1820px,
);
// scss-docs-end container-max-widths
@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12;
$grid-gutter-width: 24px;
$grid-row-columns: 6;
$gutters: $spacers;

// Container padding
$container-padding-x: calc($grid-gutter-width / 2);