.react-datepicker__month-text:hover {
  background-color: $gray-600 !important;
}

.react-datepicker__month-wrapper div[aria-label^='Not available'] {
  opacity: 0.25;
}
.react-datepicker__day--disabled:hover {
  background-color: $gray-600 !important;
}
.react-datepicker__day--disabled {
  color: $gray-200 !important;
}

.react-datepicker {
  // min-width: 290px;

  box-shadow: $box-shadow;
}
.react-datepicker-wrapper {
  flex-grow: 1;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.react-datepicker-wrapper + .btn:disabled {
  opacity: 1 !important;
}
/* REACT DISABLED STATE */
.react-select__control--is-disabled {
  opacity: 0.35;
}