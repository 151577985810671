///////////////////////////////////////////////////
/* NAVS */
///////////////////////////////////////////////////

/* Tabs with icons - centered */
.nav-tabs,
.nav-pills,
.nav-underline {
    .nav-item button {
        line-height: 1; white-space: nowrap;
        span { display: flex; gap: .5em; align-items: center; justify-content: center;
            svg { height: 1em; width: 1em; margin-top: -1px; }
        }
    }
}
/* All Tabs types with different sizes: sm, md, lg, xl */
/* tabs */
.tab-size-sm > .nav.nav-tabs .nav-item button ,
.tab-size-sm > .nav.nav-tabs .nav-item a { font-size: 0.7875rem!important; padding: .5rem .75rem; }
.tab-size-md > .nav.nav-tabs .nav-item button,
.tab-size-md > .nav.nav-tabs .nav-item a { font-size: 1rem!important; padding: .75rem .75rem; }
.tab-size-lg > .nav.nav-tabs .nav-item button,
.tab-size-lg > .nav.nav-tabs .nav-item a { font-size: 1.125rem!important; padding:1rem 1rem; }
.tab-size-xl > .nav.nav-tabs .nav-item button,
.tab-size-xl > .nav.nav-tabs .nav-item a { font-size: 1.25rem!important; padding: 1.5rem 1.5rem; }
.tab-size-xxl > .nav.nav-tabs .nav-item button,
.tab-size-xxl > .nav.nav-tabs .nav-item a { font-size: 1.5rem!important; padding: 2.25rem 2rem; }

/* pills */
.tab-size-sm > .nav.nav-pills .nav-item button ,
.tab-size-sm > .nav.nav-pills .nav-item a { font-size: 0.7875rem!important; padding: .5rem .75rem; }
.tab-size-md > .nav.nav-pills .nav-item button,
.tab-size-md > .nav.nav-pills .nav-item a { font-size: 1rem!important; padding: .75rem .75rem; }
.tab-size-lg > .nav.nav-pills .nav-item button,
.tab-size-lg > .nav.nav-pills .nav-item a { font-size: 1.125rem!important; padding:1rem 1rem; }
.tab-size-xl > .nav.nav-pills .nav-item button,
.tab-size-xl > .nav.nav-pills .nav-item a { font-size: 1.25rem!important; padding: 1.25rem 1.5rem; }
.tab-size-xl > .nav.nav-pills .nav-item button,
.tab-size-xl > .nav.nav-pills .nav-item a { font-size: 1.5rem!important; padding: 1.5rem 1.75rem; }

/* underline */
.tab-size-sm > .nav.nav-underline .nav-item button { font-size: 0.7875rem!important;  }
.tab-size-md > .nav.nav-underline .nav-item button { font-size: 1rem!important;   }
.tab-size-lg > .nav.nav-underline .nav-item button { font-size: 1.125rem!important; }
.tab-size-xl > .nav.nav-underline .nav-item button { font-size: 1.25rem!important; }


///////////////////////////////////////////////////
/* Nav tabs */
///////////////////////////////////////////////////
.nav-tabs {
    display: flex;
    z-index: 1;
    position: relative;

    li.nav-item {
        margin: 0 -1px;

        &:nth-child(1) {
            margin-left: -1px;
        }

        &:nth-last-child(1) {
            margin-right: -1px;
        }

        button {
            height: calc(100% + 1px);   
            background-color: $gray-50;
            border: 1px solid $gray-300;
        }
    }
}
/* For cases when the navs are directly inside a card header / body */
.card-body , .card-header {
   .tab-size-lg:first-child > .nav-tabs , 
   .tab-size-md:first-child > .nav-tabs , 
   .tab-size-sm:first-child > .nav-tabs , 
   .tab-size-xl:first-child > .nav-tabs {
        margin-top: -1px; margin-left: -1px;
        button {border-radius: 0!important; }
    }
    .tab-size-lg:first-child > .nav-tabs .form-select.tabselect , 
    .tab-size-md:first-child > .nav-tabs .form-select.tabselect , 
    .tab-size-sm:first-child > .nav-tabs .form-select.tabselect , 
    .tab-size-xl:first-child > .wrap-nav-select .form-select.tabselect { margin: 1.5rem 1.5rem 0 1.5rem!important; width: calc(100% - 3rem); border-radius: var(--bs-border-radius-sm) !important;}
}

///////////////////////////////////////////////////
/* Nav pills */
///////////////////////////////////////////////////
.nav-pills {
    .nav-item .nav-link.active {
        border: 1px solid $gray-300;
        box-shadow: $box-shadow-sm;
    }
      
    &.coloured-pills {
        .nav-link {
            border: 1px solid $gray-300;; background-color: $white; color: $gray-500; box-shadow: $box-shadow-sm;
            &:hover { color: $gray-700; }
            &.active {
                border:1px solid $primary; background-color: $primary; color: white;
            }
        }
    }
}
.tab-size-sm .nav-pills { gap:0.375rem; }
.tab-size-md .nav-pills { gap:.5rem; }
.tab-size-lg .nav-pills { gap:1rem; }
.tab-size-xl .nav-pills { gap:1rem; }




///////////////////////////////////////////////////
/* Nav Underline */
///////////////////////////////////////////////////
// .nav-underline {

//     .nav-link.active,
//     .show>.nav-link {
//         // font-weight: $font-weight-semibold!important;
//         // color: var(--#{$prefix}nav-underline-link-active-color);
//         // border-bottom-color: currentcolor;
//         color: $primary !important;
//         border-bottom-color: $primary !important;
//         background-color: $white;
//     }

//     &.nav-justified {
//         gap: 0;
//     }
// }

// .nav-pills {
//     position: relative;
//     z-index: 1;
// }