/**********************************************/
/* MODALS */
/**********************************************/
// scss-docs-start modal-variables
// $modal-inner-padding: $spacer - 0.5rem;
$modal-inner-padding: $spacer;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
// $modal-content-border-radius: 0.2rem;
$modal-content-border-radius: $border-radius-xl;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;

$modal-backdrop-bg: $gray-900;
$modal-backdrop-opacity: 0.7;
$modal-header-border-color: $gray-900;
$modal-footer-bg: $gray-50;
$modal-footer-border-color: $gray-300;
$modal-header-border-width: 0;
$modal-footer-border-width: 1px;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-sm: 320px;
$modal-md: 640px;
$modal-lg: 800px;
$modal-xl: 1140px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1.02);
// scss-docs-end modal-variables

///////////////////////////////////////////////////
/* MODALS */
///////////////////////////////////////////////////
.modal-header {
  background-color: $gray-900;
  color: $white;
}
.modal .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}