/**********************************************/
/* TABLES */
/**********************************************/

// scss-docs-end table-variables
$table-cell-padding-y:        .75rem;
$table-cell-padding-x:        .5rem;
$table-cell-padding-y-sm:     .25rem;
$table-cell-padding-x-sm:     .25rem;

$table-cell-vertical-align: top;

$table-color: $body-color;
$table-bg: transparent;
$table-accent-bg: transparent;

$table-th-font-weight: null;

$table-striped-color: $table-color;
$table-striped-bg-factor: 0.05;
// $table-striped-bg: rgba($gray-100, $table-striped-bg-factor);
$table-striped-bg: $gray-50;

$table-active-color: $table-color;
$table-active-bg-factor: 0.4;
$table-active-bg: rgba($gray-300, $table-active-bg-factor);

$table-hover-color: $table-color;
$table-hover-bg-factor: 0.1;
$table-hover-bg: $gray-100;

$table-border-factor: 0.1;
$table-border-width: $border-width;
$table-border-color: $gray-200;

$table-striped-order: odd;

$table-group-separator-color: lighten($gray-300, 5%);

$table-caption-color: $text-muted;

$table-bg-scale: -80%;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  'primary': shift-color($primary, $table-bg-scale),
  'secondary': shift-color($secondary, $table-bg-scale),
  'success': shift-color($success, $table-bg-scale),
  'info': shift-color($info, $table-bg-scale),
  'warning': shift-color($warning, $table-bg-scale),
  'danger': shift-color($danger, $table-bg-scale),
  'light': $white,
  'dark': $dark,
);
// scss-docs-end table-loop


