/**********************************************/
/* LINKS */
/**********************************************/
// Style anchor elements.
$link-color: $primary;
$link-decoration: none;
$link-shade-percentage: 15%;
$link-hover-color: shade-color($link-color, 25%);
$link-hover-decoration: underline;

$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;