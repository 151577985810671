.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    &.clamp-2 {
        -webkit-line-clamp: 2;
    }
}

// Dropdown Large
$dropdown-lg-width:                 320px;

// Dragula demo background
$dragula-bg:                      #f7f9fb;

// Chat widget
$chat-primary-user-bg:            #fef5e4;
$chat-secondary-user-bg:          #f1f3fa;

// User authentication Background
$auth-bg:                         #ffffff;
$auth-bg-pattern-img:               url("../../../images/bg-pattern-light.svg");

// Hero
$hero-bg: linear-gradient(to bottom,#565d71,#2b303f);


  