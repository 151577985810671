/**********************************************/
/* GENERAL STUFF SHARED BY ALL DASHBOARDS */
/**********************************************/

/* FIX SCROLLING SIDENAV IN MEDIUM SIZED DEVICES */
@media screen and (min-width: 768px) and (max-width: 1028px) {
  body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .leftside-menu {
    position: fixed !important;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

///////////////////////////////////////////////////
/* EXTRAS */
///////////////////////////////////////////////////

.clamp1 { display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; }
.clamp2 { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; }
.clamp3 { display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; }

.custom-capitalize {
  text-transform: lowercase;
}
.custom-capitalize::first-letter {
  text-transform: capitalize;
}
.img-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-help {
  cursor: help !important;
}
.grabbable {
  cursor: grab !important;
  &:active {
    cursor: grabbing !important;
  }
}
.transition-25 {
  transition: all 0.25s linear !important;
}
.signature-pad {
  cursor: crosshair;
}

svg {
  fill: inherit;
}
.claim-menu-badge {
  height: 20px !important;
  position: absolute;
  right: 30px;
}

.z-10 {
  z-index: 10 !important;
}
.z-20 {
  z-index: 20 !important;
}
.z-30 {
  z-index: 30 !important;
}

.mb-80 {
  margin-bottom: 20rem !important;
}

/* Scrollbar hiding */
.no-scrollbar {
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;     /* Hide scrollbar for Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;             /* Hide scrollbar for Chrome, Safari, and Opera */
}