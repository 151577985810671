.image-card {

  img {
    height:auto;
    width: 100%;

    object-fit: cover;
    // border-radius: 10px;
  }
}
