//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
        fill: $dark;
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-legend-series {
    font-weight: 600;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: $apex-grid-color;
}

.apexcharts-legend-text {
    color: $gray-600 !important;
    font-family: $font-family-base !important;
}



.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: $gray-500;
    }
}

.apexcharts-point-annotations,
.apexcharts-xaxis-annotations,
.apexcharts-yaxis-annotations {
    text {
        fill: $white;
    }
}

.apexcharts-radar-series {
    polygon {
        fill: transparent;
        stroke: $gray-300;
    }
    line {
        stroke: $gray-300;
    }
}

.apexcharts-pie-label, 
.apexcharts-datalabel, 
.apexcharts-datalabel-label, 
.apexcharts-datalabel-value {
    fill: $dark !important;
}

.apexcharts-datalabels-group {
    text {
        fill: $gray-500 !important;
    }
}

// Scatter images chart
.scatter-images-chart {
    .apexcharts-legend {
        overflow: hidden !important;
        min-height: 17px;
    }
    .apexcharts-legend-marker {
        background: none !important;
        margin-right: 7px !important;
    }
    .apexcharts-legend-series {
        align-items: flex-start !important;
    }
}

.apexcharts-pie-series {
    path {
        stroke: transparent !important;
    }
}

.apexcharts-track {
    path {
        stroke: lighten($gray-300,5%);
    }
}

/**************************************************/
/* APEXCHARTS Styling for store labels with logos */
/**************************************************/

.streaming-graph {
    .apexcharts-legend-series {
      .apexcharts-legend-marker {
        .custom-marker {
          position: absolute;
          inset: 0;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      &[seriesname='All'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background: #00b4d7;
            border-radius: 50%;
          }
        }
      }
      &[seriesname='Pandora'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD/SURBVHgB7dbJbcJAGIbh9zcoUQ6RTAcpgRKcShJHXOgi6SC5JopCCU4FiAqADoYKGG4IkIfBSFxYzGLrl8DfwbLsWR5pVqiiHNn59dc6Tm/J+KchlYR5+k+7YY6rVhRgu+kOs/QjDxJQWtwrd9Lne9xEB5AlpCbdQ4iyAWtEXf5QBPjRoMnPOEINsIoEL+gCXIQqAJ5QBqANsKgCXLZVKwJEBugCgg6KAMPbYw81wMw97/tVp/TIO+3QoACwOBfTCpNDhcoA+PUunwTpF3HD5hU+F2A2b9n6FuPvVtbP9ARZDInD3I4vA7j7iNbDiAJyU2dBBSgMYKhNJ1S5liwBsZs+SI5EwPYAAAAASUVORK5CYII=');
          }
        }
      }
      &[seriesname='SoundCloud'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGYSURBVHgB7ZbBUcJAFIb/FyIcpQNeB2IFpAM5iDBe1BLsgBLsADwqOoMVMFQAVuDSATcRyD53M+BARAmJkxzMl8kk2bxN/n37/k2AnJz/DiEBixZ7pFEWB1N3hjH11RQHElvA/JKrhaWMQg8ba9Cd+6juoz7HiRpoRztvcPWro0Y1HCNAlSBd/6Ly9l5nxl8JsKN1tAwKJKPFOXsRunCxKIMoIiIJ2BwtOaggGlw6ks6+oB8FbKY7LkLw9mVspwC/yR2bbt2oDJAQIlz9dt/d2QnimYKyI2AkFiDXulnxzKnSQt2wQyK7IAkiYLN7uxySioAQWw7JQkAgYu2QrAQEDpE6l11pMYtZZGzROXM6jbOex6aEmuObRca8nM0l+0WcIWUcs5WREQuNSWY1YFDFnhpvCSgQUpt/AbXt0S3M0NclqZE4ZXpQL0gDLW33aRKsiO6q6m+2A0iBhE1xjgO1Syha5cpk6VvbASjx6dZ9nvTXDTv/iKw/rUXwgeHalvbreAQcU08N13FBG+EEEVgIXu2cIycnJ8QngUidGJHm4SEAAAAASUVORK5CYII=');
          }
        }
      }
      &[seriesname='Spotify'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOOSURBVHgBtVdNTttQEJ55dlpUukjXJWBOgDkBYVFQVw0ikbojPQHcIOEEwAkIu0pQEVY02RBO0PQEGDgA3hShJn7TeS9x6t/gQDJSFNvveeabv8/zEDKKdV7Ke/O5El+uEZGNCBYQ5PUigksEDiJ2+e7a+NNrOltNN4tefG6DdfnV8kR/l3dWRwYzacaG4Rn7zufvzthtaQvKY/nOrBHAHrxCUED97tPZfup60sOB194VAFkwFUHHkMZ6UjREzHi7Yk/XuBLSDn1k3dGVUASm73nMXCwSoQjM1riSQSRUfcUALLTKtdkaD4B4b9b8O52CYavdJL+ADsPscgu6SHQbXZVEeTTEEq9ZzAU2ZBRDmssqFaa68QyvxgYCNqEDREfGo9fJSii+6CL2GIgJXxhdKW1fH3uqvffQumKG+2s+hNA99j9MajgRjGLPuVyJuYD5JJZel+0sm15P0SuFl+bmVJG4vhKYz1kegK3CHLMiwOVC4hSh62ycdoNLQyca6le4rFQjQDS1Y6FdOeZwV8NaOe86DVCcuDD5Pf5OXIi+2YwST6G1zc7i+Wgr07VgRAmFw0Y1qBd0BYMmCQeqqLmzfrGD1f8GxVJks42L7fIDJXxkUH3hOLQo4YKQmEByXXh6coO1MUoPSdUBRRS4MohaTNswotFIc2cVWmWCBPHbBCYU3dLgFdGg3SxtmQZAV6jvrW4tVYRIeZCBaPkF2DedJLALl+WiEHhMY1KZmoJB2MjhCzvbHOCHWV7cb/5oBlcK7fJVcmrYAcndkqxQFSK/lHkI8QsXzwutyk2w+DS4JMjIU9Riq9zgHOykbHAJsUOevEUhuNelSxJDBCUQbMnGOdRr8Zyjw+F3EVJqgdvQVAYYeQyA6oD7jbNVeF46/sWoAEeEQ9bYmc+Da03FsmfeJNSBayCucx3kOU02kW6xvLqPIO2yJ795/usEC1Ezn0EHNCaFivI1wMWf24cciV14pWjeQDy63zhtPKuXw8/7vmX4HL9E0EGgEwLcSWNTn2dGKVpgtDiFKGSCB7B/t3lWV9ejich869UxpV2maxwd33gIgLPeZFIw1mcJQulWNoLPQkOpygn39dYsQGjjrDtK2akHE8nTK01pSPU9z3QwUaIj8aa3yofQI3ilKB3isbea9mXNejitQwpdJyplCpeSTkzKHb74cBoDMhwwQVCRDayoRz7LKYP8x7w/+fH8H+Cd4vWqNjbGAAAAAElFTkSuQmCC');
          }
        }
      }
      &[seriesname='Amazon'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGiSURBVHgBfVPrTcNADLad8pJ43AZkA8IGiRD8hg1gAmCClgmACYAJgF9IFFQ2oExA2CDiIUSLbXxtL21pWkuRnTt/9uezjfBPuk2XCvIhIKSg4EwXpp8kio6XsiIf9cXRn5+HlV1T11AtBYpk8ztf7XBAY9eIp8EU1RP7MgQMzg4o2h91rwVDW851mXMzcwF9Wdr+bAzYnAQ2FiyuBGNWFKayMtidi3kOEkZJQLVPE2GtEhyy/4ocWuajjrLrH8JUGQMb7WvzTXtMAJ4Y4cpqdqh6OhPcaxFw2q8B8vmtj6w8R67CDl+bwWobCFpfg/1LHJdlGYtKsJIWQyfY7dy7xGclwPrQXZPv5nI6AV6k6MZUCOCU+NnotgzgRnptFVEyAfatsj7u2aDkQ0cbS4o2OaI9H0AEDha338/K+5/makNqdPl/bqdJ535lXwgTAt0gD4yYL7qPq/XvlotnAf0cYITrBPzqO1Iuhj1EgxDrnqpRbwvAm0UfvAFu2JQlihir8DnVbAZgrhjbKp8ZWY48JUVIwkpa69qCeLtAdDkY4578ARbeu12S7JeuAAAAAElFTkSuQmCC');
          }
        }
      }
      &[seriesname='Apple'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACuSURBVHgBnZLBDcIwDEW/4MKNjJAjYzACI3QE2KQbhA3KBhmBM6eMUI69lR/VlYxVRN0vPaly+mqnMvA/gXTkgQ0pZCQtnLmIWD8Q7eF+QahjHshATuRFbup8mB92qhhJJr2QpXYkT+le60kafInz3dZw1XJyiAkmo4NoR/bI0D/sDV/OtlAcnbOVW/hGr+sa9Cgeudjud4fcWDlg3d07/EjEtIq9vNRg2qa8dNcP/9186fUDAKMAAAAASUVORK5CYII=');
          }
        }
      }
      &[seriesname='Deezer'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAQCAYAAAD0xERiAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD8SURBVHgB5VLBjcIwEJwNefC4k9LB5TrI+15cBdABlEAqQFQCdEAH5ANIvOgA00FAPJDAmA2geA3xJy8kVrK9O/aOZ6QF3jUIdWOx64OoXdYNnYaoG4SE91ZZ6zCqT2ZoCjLKAlpVky33I9GU4+87xauyiLcfCfmU9WzT7fe0QlqLL7uCauIjGzvKKoOy4rYsG5R7yMxW5DnmhwSB7lgsyGAYJ/FOn3xkNLApFOiSOxguBR4/2cx8NjORKzbDKiRG6uHQYmyTsGIL2kQl2DyvcQwTh7r5xdhBYKG6n+dY9hGPwQxy+KB/+ZuNowwY8rLjAlPUsWuT/gN8RFwBn55Op0LqC0EAAAAASUVORK5CYII=');
          }
        }
      }
      &[seriesname='YouTubeMusic'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF2SURBVHgBlVO/SwJhGH6MiHORWsRB444GwUMFZwf/CCEQ4YgGp3BraagpaHB0cRBbGsP/oEUQKWgpmtIClxpUHLN8e85Tuvu6S/rg4X2/98fzPe993wHKEmCHOCXuiBkhxBtx/QkUsW6xsEqMl41BuCf0IIKzNc1uDH4RMXDoWxyLiZhmENEjEbH7Q3Q02ie4mbNZIJPhYGNgNGKGKY1lzaY6wEkIOLdVFD0n6LpIpSISiXhPTiScuKJmNcqlJ1EuO7ZQEGm1HNJVzo6l0ypRfIM8ux6B8/mPb1n8hAOgXmdpHOh0gFxOHWlv0xHjWuGwt2QyAfp9YDh09rOZShKylTwHkrTbgGkCtZqzz+eBblclebVl7HtmNAwRy/K/7mpVjT8spNDZpuXgC+sswwBKJaDXA6ZTIJUCtraARkNVccArbi08Eh35PqhoVCSZFNE031erMv732b/89f8cEx9rCG4CCVxE+hdwQXtLzJeN78QVUfDr+QZhvTvd/IeMugAAAABJRU5ErkJggg==');
          }
        }
      }
      &[seriesname='Others'] {
        .apexcharts-legend-marker {
          .custom-marker {
            background: #727cf5;
            border-radius: 50%;
          }
        }
      }
    }
  }
  
/**************************************************/
/* Changes:
- tooltip bg opacity
- separation between lines
*/
/**************************************************/
.apexcharts-tooltip-series-group.apexcharts-active, .apexcharts-tooltip-series-group:last-child { padding-bottom: 0!important;}
  .apexcharts-tooltip.apexcharts-theme-light { background: rgba(255,255,255,.75)!important;}