/**********************************************/
/* INPUTS */
/**********************************************/
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         .75rem;
$input-btn-font-family:       null;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width: 0.1rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba($primary, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;

$input-btn-border-width:      $border-width;
// scss-docs-end input-btn-variables

/**********************************************/
/* BUTTONS */
/**********************************************/
// scss-docs-start btn-variables
// $btn-padding-y: $input-btn-padding-y;
$btn-padding-y: 0;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

// $btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-y-sm: 0;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

// $btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-y-lg: 0;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: 1rem;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: 0 0 0; // button box shadow
$btn-focus-width: 0.15rem;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.35;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $gray-500;

// Allows for customizing button radius independently from global border radius
// $btn-border-radius:           $border-radius;
// $btn-border-radius-sm:        $border-radius-sm;
// $btn-border-radius-lg:        $border-radius-lg;
$btn-border-radius: $border-radius-sm;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-sm;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;
// scss-docs-end btn-variables


