/**********************************************/
/* GRAPHS */
/**********************************************/
.graph {
    width: 100%;
    height: auto;
    padding-bottom: 0%;
    img {
      width: 100%;
    }
}
// Apex chart
$apex-grid-color:                 var(--bs-gray-200);
