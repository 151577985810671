//
// SuisseIntl fonts
//
@font-face {
    font-family: "SuisseIntl";
    src: url("./SuisseIntl-Light-WebS.woff2") format("woff2"),
      url("./SuisseIntl-Light-WebS.woff") format("woff");
    font-weight: 300;
  }
  @font-face {
    font-family: "SuisseIntl";
    src: url("./SuisseIntl-Regular-WebS.woff2") format("woff2"),
      url("./SuisseIntl-Regular-WebS.woff") format("woff");
    font-weight: 400;
  }
  @font-face {
    font-family: "SuisseIntl";
    src: url("./SuisseIntl-Medium-WebS.woff2") format("woff2"),
      url("./SuisseIntl-Medium-WebS.woff") format("woff");
    font-weight: 500;
  }
  @font-face {
    font-family: "SuisseIntl";
    src: url("./SuisseIntl-SemiBold-WebS.woff2") format("woff2"),
      url("./SuisseIntl-SemiBold-WebS.woff") format("woff");
    font-weight: 600;
  }
  @font-face {
    font-family: "SuisseIntl";
    src: url("./SuisseIntl-Bold-WebS.woff2") format("woff2"),
      url("./SuisseIntl-Bold-WebS.woff") format("woff");
    font-weight: 700;
  }
  @font-face {
    font-family: "SuisseIntlMono";
    src: url("./SuisseIntlMono-Regular-WebS.woff2") format("woff2"),
      url("./SuisseIntlMono-Regular-WebS.woff") format("woff");
    font-weight: 400;
  }
  @font-face {
    font-family: "SuisseIntlMono";
    src: url("./SuisseIntlMono-Bold-WebS.woff2") format("woff2"),
      url("./SuisseIntlMono-Bold-WebS.woff") format("woff");
    font-weight: 700;
  }



