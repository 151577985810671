/***********************************************/
/* SHADOWS */
/***********************************************/
// scss-docs-start box-shadow-variables
$box-shadow-xs: 0 0.125rem 0.125rem rgba($black, 0.05);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 0.25rem 0.5rem rgba($black, 0.075);
$box-shadow-lg: 0 0.375rem 1rem rgba($black, 0.1);
$box-shadow-xl: 0 .5rem 1.5rem rgba($black, .125);
$box-shadow-2xl: 0 1rem 3rem rgba($black, .175);

// $box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);
$box-shadow-inset: inset 0 3px 9px rgba($black, 0.1);
// scss-docs-end box-shadow-variables