/***********************************************/
/* BORDERS */
/***********************************************/
// Define common padding and border radius sizes and more.
// scss-docs-start border-variables
$border-width: 1px;
$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
);
$border-color: $gray-300;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
// $border-radius: 0.25rem;
// $border-radius-sm: 0.2rem;
// // $border-radius-lg:            .3rem;
// $border-radius-lg: 0.4rem;
// $border-radius-pill: 50rem;
$border-radius: 0.375rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables