//
// custom-scrollbar.scss
//

.custom-scrollbar__wrapper {
  overflow: scroll;
  height: 10px;
}

.custom-scrollbar__content {
  background-color: $gray-900;
  height: 10px;
}
