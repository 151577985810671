/**********************************************/
/* CARDS */
/**********************************************/
// scss-docs-start card-variables
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-title-spacer-y: $spacer;
$card-border-color: $gray-300;
$card-border-width: $border-width;
$card-border-radius: $border-radius-xl;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: $card-spacer-y;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: null;
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: $white;
$card-img-overlay-padding: $spacer - 0.25rem;
$card-group-margin: calc($grid-gutter-width / 2);
// scss-docs-end card-variables


///////////////////////////////////////////////////
/* CARDS */
///////////////////////////////////////////////////
.card {
    margin-bottom: $grid-gutter-width;
    overflow: hidden;
    box-shadow: $box-shadow;
    .header-title {
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-size: $font-size-base;
        margin-top: 0;
    }
  }
  
// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
  }
.bg-dark .card {
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.5);
}